import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "state", "unsent", "count"];
  static values = {
    required: Array
  }

  connect() {
    console.log("Connect")
    this.visibleReview()
    this.visibleOtherCategory()
    this.visibleReason()
    this.localStorageKey = window.location;
    this.renderCount()
    this.changeSubmitButton()
  }

  changeSubmitButton() {
    const submitButton = document.querySelector('button[type="submit"]');
    if (this.formTarget.checkValidity()) {
      submitButton.classList.remove('disabled');
    } else {
      submitButton.classList.add('disabled');
    }
  }

  onChange(event) {
    if (event.currentTarget.name == 'form[入社可能時期]') {
      this.visibleReason()
    } 
    if (event.currentTarget.id == "form_直近の職種カテゴリ_その他__その他") {
      this.visibleOtherCategory()
    }
    if (event.currentTarget.name == 'form[withworkをどこで知りましたか？]') {
      this.visibleReview()
    }
  }

  visibleReason() {
    const otherTextChecked = document.querySelector('#form_入社可能時期_未定:checked');
    if (otherTextChecked) {
      document.querySelector('#form-uncertain_start_date_reason__c').style.display = 'block';
      document.querySelector('#form_未定の理由').setAttribute('required', '')
    } else {
      document.querySelector('#form-uncertain_start_date_reason__c').style.display = 'none';
      document.querySelector('#form_未定の理由').removeAttribute('required');
    }
  }

  visibleOtherCategory() {
    const otherTextChecked = document.querySelector('#form_直近の職種カテゴリ_その他__その他:checked');
    if (otherTextChecked) {
      document.querySelector('#form-TalentRecentJobFreeText__c').style.display = 'block';
    } else {
      document.querySelector('#form-TalentRecentJobFreeText__c').style.display = 'none';
    }
  }

  visibleReview() {
    const checked = document.querySelector('#form-ninchikeiro__c input:checked');
    if (checked && checked.value == '知人の口コミ・紹介') {
      document.querySelector('#form-introducer__c').style.display = 'block';
    } else {
      document.querySelector('#form-introducer__c').style.display = 'none';
    }
  }

  countRequiredAndFilled() {
    const payload = new FormData(this.formTarget);
    let totalRequired = 0;
    let filledRequired = 0;

    for (let [name, value] of payload.entries()) {
      const element = this.formTarget.elements[name];

      // 非表示要素のスキップ
      if (element.offsetParent === null && !(element instanceof RadioNodeList) && !element.checked) {
        continue;
      }

      let isRequired = false;
      let isFilled = false;

      // RadioNodeListの場合の処理
      if (element instanceof RadioNodeList) {
        isRequired = element[0].hasAttribute('required');
        isFilled = Array.from(element).some(radio => radio.checked);
      }
      // 通常の要素の場合の処理
      else {
        isRequired = element.hasAttribute('required');
        if (element.type === 'checkbox') {
          isFilled = element.checked;
        } else {
          isFilled = value.length > 0;
        }
      }

      if (isRequired) {
        totalRequired++;
        if (isFilled) {
          filledRequired++;
        }
      }
    }

    return {
      required: totalRequired,
      filled: filledRequired
    };
  }

  renderCount() {
    const result = this.countRequiredAndFilled();
    if (result.filled < result.required) {
      this.countTarget.innerHTML = `必須：${result.required} / 入力済み：${result.filled}`
    } else {
      this.countTarget.innerHTML = ''
    }
  }

  perist(e) {
    this.renderCount();

    // show auth-save
    const section = e.target.closest('.input');
    this.stateTarget.dataset.state = 'saving';
    this.stateTarget.style.top = `${section.offsetTop}px`;

    const payload = new FormData(this.formTarget);
    // perist data
    const token = document.getElementsByName('csrf-token')[0].content
    const headers = {
      'X-CSRF-Token': token,
      'Accept': 'application/json',
    }
    fetch(location.href, {
      method: 'PATCH',
      headers: headers,
      body: payload,
    })
    .then(_ => {
      this.stateTarget.dataset.state = 'saved';
      this.unsentTarget.dataset.state = 'true';
    });
  }
}
