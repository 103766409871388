import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

interface Station {
  id: string;
  name: string; // text を name に変更
}

export default class extends Controller<HTMLSelectElement> {
  static values = {
    url: String,
  };

  declare readonly urlValue: string;
  private declare tomSelect: TomSelect;

  connect() {
    this.tomSelect = new TomSelect(this.element, {
      valueField: "name",
      labelField: "name",
      searchField: ["name"],
      load: (query: string, callback: (results: Station[]) => void) => {
        const url = `/api/stations?q=${encodeURIComponent(query)}`;
        fetch(url)
          .then((response) => response.json())
          .then((json: Station[]) => {
            console.log("Received data:", json); // デバッグ用ログ
            callback(json);
          })
          .catch((error) => {
            console.error("Error fetching data:", error); // エラーログ
            callback([]);
          });
      },

      render: {
        option: function (item: Station, escape: (str: string) => string) {
          return "<div>" + escape(item.name) + "</div>";
        },
        item: function (item: Station, escape: (str: string) => string) {
          return "<div>" + escape(item.name) + "</div>";
        },
        no_results: function () {
          return '<div class="no-results">該当する駅が見つかりません</div>';
        },
        loading: function () {
          return '<div class="spinner">読み込み中...</div>';
        },
      },

      placeholder: "駅を選択してください",
      create: false,
    });
  }

  disconnect() {
    this.tomSelect.destroy();
  }
}
